import React from 'react'

import { ICommand, Commands } from './commands'

const lastLoginDate = new Date()

const createHelpCommandNode = (): ICommand => {
  const output = (
    <div>
      <h4>Available commands:</h4>
      <ul>
        {Commands.map((cmd, index) => {
          if (cmd.isHidden) {
            return <></>
          }
          return (
            <li key={index}>
              {cmd.input}
              {cmd.help ? ` - ${cmd.help}` : ''}
            </li>
          )
        })}
      </ul>
    </div>
  )
  return { input: 'help', output: output }
}

const App = () => {
  const inputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>
  const [commands, setCommands] = React.useState<Array<ICommand>>([
    Commands.find((cmd) => cmd.input === 'welcome')!,
  ])
  const [input, setInput] = React.useState<string>('')

  React.useEffect(() => {
    inputRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [commands])

  return (
    <div className="command-line">
      <ul>
        <li>Welcome: Last Login {lastLoginDate.toLocaleString()}</li>
        {commands.map((command, index) => {
          const { input, output } = command
          return (
            <li key={index}>
              <div>
                <span className="prompt">~ $</span>
                {input}
              </div>
              <div className="output">{output}</div>
            </li>
          )
        })}
        <li className="user-input">
          <div className="prompt">~ $</div>
          <input
            ref={inputRef}
            type="text"
            value={input}
            autoFocus
            autoCorrect="off"
            autoCapitalize="off"
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (input === 'help') {
                  commands.push(createHelpCommandNode())
                } else if (input === 'clear') {
                  commands.splice(0, commands.length)
                } else {
                  const cmd = Commands.find((cmd) => cmd.input === input)
                  if (cmd !== undefined) {
                    commands.push(cmd)
                  } else {
                    commands.push({
                      input: input,
                      output: (
                        <div>Unknown command '{input}'. Try help instead.</div>
                      ),
                    })
                  }
                }

                setCommands(commands)
                setInput('')
              }
            }}
          />
        </li>
      </ul>
    </div>
  )
}

export default App
