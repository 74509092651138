export interface ICommand {
  input: string
  output: JSX.Element
  help?: string
  isHidden?: boolean
}

export const Commands: Array<ICommand> = [
  {
    input: 'welcome',
    output: <div>AngryBoat, LLC | A software company in Texas.</div>,
  },
  {
    input: 'clear',
    output: <div>if you're seeing this message, something went wrong...</div>,
  },
  {
    input: 'projects',
    output: (
      <div>
        Current Projects:
        <p>
          &bull; <a href="https://studios.angryboat.com/">AngryBoat Studios</a>
        </p>
      </div>
    ),
  },
  {
    input: 'about',
    output: (
      <div>
        A little software company based in Dallas, TX. We work on client
        projects, as well as some of our own products.
      </div>
    ),
  },
  {
    input: 'contact',
    output: (
      <div>
        <a href="mailto:contact@angryboat.com">Via Email</a>
      </div>
    ),
  },
  {
    input: 'links',
    output: (
      <div>
        <ul>
          <li>
            <a href="https://github.com/angryboat/">GitHub</a>
          </li>
        </ul>
      </div>
    ),
  },
  {
    input: 'pwd',
    isHidden: true,
    output: <div>{window.location.href}</div>,
  },
  {
    input: 'ls',
    isHidden: true,
    output: <div>index.html</div>,
  },
].sort((a, b) => a.input.localeCompare(b.input))
